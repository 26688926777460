import uuidv4 from 'uuid/v4';

// Setup the empty todos array
let todos = [];

// Fetch existing todos from localStorage
const loadTodos = () => {
    const todosJSON = localStorage.getItem("todos");

    try
    {
        todos = todosJSON ? JSON.parse(todosJSON) : [];
    }
    catch (e)
    {
        todos = [];   
    }
};

// Save todos to localStorage
const saveTodos = () => {
    localStorage.setItem('todos', JSON.stringify(todos));
};

// Get todos
const getTodos = () => todos;

const createTodo = (text) => {
    todos.push({
        id: uuidv4(),
        text,
        completed: false
    });
    saveTodos();
}

// Remove a todo from the list
const removeTodo = (id) => {
    const todo_index = todos.findIndex((note) => note.id === id);
    if(todo_index > -1)
    {
        todos.splice(todo_index, 1);
        saveTodos();
    }
    
};

// Modify todo completed status if checkbox is checked
const toggleTodo = (id) => {
    const todo = todos.find((todo) => todo.id === id);

    if (todo)
    {
        todo.completed = !todo.completed;
        saveTodos();
    }
};

// Call loadTodos to populate it and setup the exports
loadTodos();

export  { loadTodos, getTodos, createTodo, removeTodo, toggleTodo }