import { getTodos, toggleTodo, removeTodo } from './todos.js';
import { getFilters } from './filters.js';


// Render application todos based on filters
const renderTodos = () => {
    const todo_element = document.querySelector("#todos");
    const { searchText, hideCompleted } = getFilters();
    const filtered_todos = getTodos().filter((todo) => {
        const search_text_match = todo.text.toLowerCase().includes(searchText.toLowerCase()); 
        const hide_completed_match = !hideCompleted || !todo.completed;
        
        return search_text_match && hide_completed_match;
    });

    const incomplete_todos = filtered_todos.filter((todo) => !todo.completed);

    todo_element.textContent = "";
    generateSummaryDOM(incomplete_todos);

    if(filtered_todos.length > 0)
    {
        filtered_todos.forEach((todo) => {
            todo_element.appendChild(generateTodoDOM(todo));
        });
    }
    else
    {
        const message_element = document.createElement("p");
        message_element.classList.add("empty-message");
        message_element.textContent = "There are no todos to show";
        todo_element.appendChild(message_element);
    }

};

// Get the DOM elements for an individual note
const generateTodoDOM = (todo) => {
    const todo_element = document.createElement("label");
    const container_element = document.createElement("div");
    const checkbox = document.createElement("input")
    const todo_text = document.createElement("span");
    const remove_button = document.createElement("button");

    // Setup todo checkbox
    checkbox.setAttribute("type", "checkbox");
    if (todo.completed)
    {
        checkbox.checked = todo.completed;
    }
    container_element.appendChild(checkbox);
    checkbox.addEventListener("change", () => {
        toggleTodo(todo.id);
        renderTodos();
    });

    // Setup the todo text
    todo_text.textContent = todo.text;
    container_element.appendChild(todo_text);

    // Setup container
    todo_element.classList.add("list-item");
    container_element.classList.add("list-item__container");
    todo_element.appendChild(container_element);

    // Setup the remove button
    remove_button.textContent = "remove";
    remove_button.classList.add("button", "button--text")
    todo_element.appendChild(remove_button);
    remove_button.addEventListener("click", () => {
        removeTodo(todo.id);
        renderTodos();
    });

    return todo_element;
};

// Get the DOM elements for list summary
const generateSummaryDOM = (incomplete_todos) => {
    const summary = document.createElement("h2");
    summary.classList.add("list-title");
    const plural = incomplete_todos.length === 1 ? '' : 's';
    summary.textContent = `You have ${incomplete_todos.length} todo${plural} left`;
    document.querySelector("#todos").appendChild(summary);
};

export { generateTodoDOM, generateSummaryDOM, renderTodos }