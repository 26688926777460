import { setFilters, getFilters } from './filters.js';
import { loadTodos, createTodo } from './todos.js';
import { renderTodos } from './views.js';

renderTodos();

// Search text
document.querySelector("#search-text").addEventListener("input", (e) => {
    let filters = getFilters();
    filters.searchText = e.target.value;
    setFilters({
        searchText: e.target.value
    })
    renderTodos(todos, filters);
});

// Add todo
document.querySelector("#add-todo-form").addEventListener("submit", (e) => {
    const text = e.target.elements.addTodo.value.trim();
    e.preventDefault();

    if (text.length > 0)
    {
        createTodo(text);
        renderTodos();
        e.target.elements.addTodo.value = '';        
    }
});

// Hide completed 
document.querySelector("#hide-completed").addEventListener("change", (e) => {
    setFilters({
        hideCompleted: e.target.checked
    })
    renderTodos();
});

// Sync localstorage across multiple instances
window.addEventListener('storage', (e) => {
    if (e.key === 'todos') 
    {
        loadTodos();
        renderTodos();
    }
});